import { useUserStore } from "~/store/user";

export default defineNuxtRouteMiddleware((to) => {
    const userStore = useUserStore();

    const redirectRouteByOrigin: Record<string, string> = {
        "/statistics/ranked": "/statistics",
    };

    if (!userStore.user) {
        return navigateTo(redirectRouteByOrigin[to.path] ?? "/", {
            redirectCode: 401,
        });
    }
});
